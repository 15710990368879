<template>
  <div class="gallery-table">
    <GalleryFilter
      class="gallery-filter-box"
      @search="getItems"
    />
    <Preloader v-if="callingAPI"></Preloader>

    <div class="gallery-table__td-row">
      <div
        class="gallery-table__td-col"
        v-for="gallery in items" :key="`item-${gallery.id}`"
      >
        <div class="gallery-table__td">
          <div
            class="media-list"
            @click="showGalleryPreview(gallery)"
          >
            <div
              v-for="media in gallery.expanded.medias.slice(0,4)" :key="`item-${gallery.id}-media-${media.id}`"
              class="media-list-item"
              :style="'background-image: url(' + getDamImagePath(media) + ')'"
            ></div>
          </div>
          <div class="meta-info m-b-10">
            <div class="cropText">
              <span class="meta-info__header">
                {{ $t('dam.id') }}:
              </span>
              <span class="meta-info__value">
                {{ gallery.id }}
              </span>
            </div>
            <div class="cropText">
              <span class="meta-info__header">
                {{ $t('modal.title') }}:
              </span>
              <span class="meta-info__value" >
                {{ gallery.title }}
              </span>
            </div>
            <div class="cropText">
              <span class="meta-info__header">
                {{ $t('modal.site') }}:
              </span>
              <span class="meta-info__value">
                {{ $store.getters['site/siteById'](gallery.site).title }}
              </span>
            </div>
            <div class="cropText">
              <span class="meta-info__header">
                {{ $t('gallery.image_count') }}:
              </span>
              <span class="meta-info__value">
                {{ gallery.expanded.medias.length }}
              </span>
            </div>
            <div class="cropText">
              <span class="meta-info__header">
                {{ $t('modal.used_count') }}:
              </span>
              <span class="meta-info__value">
                {{ gallery.usedCount }}
              </span>
            </div>
            <div class="cropText">
              <span class="meta-info__header">
                {{ $t('gallery.created') }}:
              </span>
              <span class="meta-info__value">
                {{ gallery.createdAt ? $options.filters.prettyDateTime(gallery.createdAt) : '' }}
                {{ `, ` }}
                {{ getUserNameById(gallery.createdBy) }}
              </span>
            </div>
          </div>
          <div v-if="modal === false" class="meta-info__buttons">
            <button
              v-if="isEditButtonVisible(gallery)"
              class="meta-info__button meta-info__button__edit"
              type="button"
              @click="$router.push({name: 'gallery_edit', params: { id: gallery.id }})"
            >
              <IconEdit class="meta-info__button__icon" />
              <div class="meta-info__button__text">
                {{ $t('buttons.edit') }}
              </div>
            </button>
            <button
              class="meta-info__button"
              type="button"
              @click="$router.push({name: 'gallery_detail', params: { id: gallery.id }})"
            >
              <IconInfo class="meta-info__button__icon" />
            </button>
            <BtnCopyToClipboard
              class="meta-info__button__copy"
              :data="gallery.id"
              :notify-message="$t('notify.id_was_copied')"
            />
            <ButtonDelete
              v-if="isDeleteButtonVisible(gallery)"
              store-name="gallery"
              :record-id="gallery.id"
            />
          </div>
          <div v-if="modal" class="meta-info__modal-buttons">
            <button
              class="meta-info__button meta-info__modal-button-left"
              type="button"
              @click="showGalleryPreview(gallery)"
            >
              <i class="meta-info__button__icon fas fa-search"></i>
              <div class="meta-info__button__text">
                {{ $t('buttons.preview') }}
              </div>
            </button>
            <button
              class="meta-info__button meta-info__modal-button-right"
              type="button"
              @click="select(gallery)"
            >
              <i class="meta-info__button__icon fas fa-check"></i>
              <div class="meta-info__button__text">
                {{ $t('buttons.select') }}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="gallery-table__bottom-bar">
      <TotalCount :total-count="total" />
      <Pagination
        v-if="items.length"
        :current-page="page"
        :page-count="pageCount"
        :click-handler="pageChange"
        v-model="page"
      />
    </div>
    <Modal
      :title="$t('article.gallery')"
      modal-size="modal-lg"
      @close="closePreview"
      v-if="previewItem"
    >
      <template slot="body">
        <GalleryView :is-modal="true" :gallery-id="previewItem.id" />
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MediaMixin from '../../components/mixins/Media'
import GalleryView from './GalleryView.vue'
import GalleryFilter from './components/GalleryFilter'
import Modal from '../../components/shared/Modal'
import Preloader from '../../components/preloader/Preloader'
import UserService from '@/services/user/UserService'
import Pagination from '@/components/Pagination'
import TotalCount from '@/components/article/ArticleTotalCount'
import IconInfo from '@/assets/img/svg/info.svg?inline'
import IconEdit from '@/assets/img/svg/edit.svg?inline'
import PermissionService from '@/services/PermissionService'
import ButtonDelete from '@/components/buttons/ButtonDelete'
import config from '@/config'
import BtnCopyToClipboard from '@/components/buttons/BtnCopyToClipboard'

export default {
  name: 'GalleryListView',
  mixins: [MediaMixin],
  components: {
    BtnCopyToClipboard,
    ButtonDelete,
    GalleryView,
    GalleryFilter,
    Modal,
    Preloader,
    TotalCount,
    Pagination,
    IconInfo,
    IconEdit
  },
  props: {
    modal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    items () {
      return this.$store.getters['gallery/list']
    },
    page () {
      return this.$store.getters['gallery/page']
    },
    total () {
      return this.$store.getters['gallery/totalCount']
    },
    pageCount () {
      return Math.ceil(this.total / config.defaults.list.limit)
    }
  },
  data () {
    return {
      previewItem: null
    }
  },
  methods: {
    async getItems () {
      try {
        await this.$store.dispatch('gallery/fetch')
      } catch (error) {
        console.error(error)
      }
    },
    pageChange (page) {
      this.$store.commit('gallery/setPage', page)
      this.getItems()
    },
    select (gallery) {
      this.$emit('select', gallery)
    },
    detail (gallery) {
      this.$emit('detail', gallery)
    },
    showGalleryPreview (item) {
      this.previewItem = item
    },
    closePreview () {
      this.previewItem = null
    },
    getUserNameById (id) {
      const user = this.$store.getters['user/userById'](id)
      if (user) {
        return UserService.getUserInfo(user)
      }
      return id
    },
    hasPermission (requiredPermissions, gallery) {
      if (gallery.site) {
        return this.$store.getters['user/hasPermissionForSite'](requiredPermissions, gallery.site)
      }
      return this.$store.getters['user/hasPermission'](requiredPermissions)
    },
    isEditButtonVisible (gallery) {
      const requiredPermissions = PermissionService.REQUIRED_PERMISSIONS.GALLERY_PERMISSIONS.editButton
      return this.hasPermission(requiredPermissions, gallery)
    },
    isDeleteButtonVisible (gallery) {
      const requiredPermissions = PermissionService.REQUIRED_PERMISSIONS.GALLERY_PERMISSIONS.deleteButton
      return this.hasPermission(requiredPermissions, gallery)
    }
  }
}
</script>

<style lang="scss" scoped>
.media-list {
  height: 220px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
}

.media-list-item {
  height: 48%;
  width: 48%;
  margin: 1%;
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
}

.meta-info {
  float: left;
  width: 100%;
  margin-bottom: rem(20px);
  font-size: rem(12px);
  &__header {
    color: #8A96AC;
  }
  &__value {
    color: #465674;
  }
  &__link-header {
    float: left
  }
  &__link {
    color: #6599FE;
    text-decoration: underline;
    display: block;
    text-align: right;
    cursor: pointer;
  }
  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &__button {
    background: #fff;
    border-radius: rem(4px);
    height: rem(30px);
    border: 1px solid #D1DBE4;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: rem(5px);
    &:hover {
      border: rem(1px) solid #6599FE;
      .meta-info__button__icon {
        fill: #6599FE;
      }
    }
    &__icon {
      width: rem(16px);
      height: auto;
      fill: #9DAAC4;
    }
    &__edit {
      flex: 0 0 55%;
    }
    &__copy {
      margin-right: rem(5px);
    }
    &__text {
      color: #465674;
      font-size: rem(12px);
      font-weight: 500;
      padding-left: rem(5px);
    }
  }
  &__modal-button-left {
    float: left;
  }
  &__modal-button-right {
    float: right;
  }
}

.cropText {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    float: left;
    text-overflow: ellipsis;
}

.gallery-item {
    width: 25%;
    display: inline-block;
}

small {
  width: 100%;
}

center button.btn-info {
  width: 48%;
}

.gallery-filter-box::after {
  height: rem(1px);
  content: '';
  background: #d1dbe4;
  margin-bottom: rem(10px);
}

.gallery-table {
  &__td {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    margin: rem(10px);
    padding: 1rem;

    &-col {
      @include bp(0 10) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @include bp(12) {
        flex: 0 0 33.3%;
        max-width: 33.3%;
      }
      @include bp(14) {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
    &-row {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__bottom-bar {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    justify-content: space-between;
    margin-top: rem(30px);
    padding-bottom: rem(30px);
  }
}

</style>
